import { defineStore } from 'pinia';

import type * as monaco from 'monaco-editor';

// Use options store for better dev tools integration
export const useEditorStore = defineStore('Editor', {
  state: () => ({
    loading: false,
    editor: null as monaco.editor.IStandaloneCodeEditor | null,
    position: null as monaco.Position | null,
    fresh: true,
    selectAll: false,
    copyAction: false,
  }),

  getters: {
    lineNumber: (state) => state.position?.lineNumber,
    column: (state) => state.position?.column,
    hasEditor: (state) => state.editor != null,
    isFresh: (state) => state.fresh === true,
    isSelectAll: (state) => state.selectAll === true,
  },

  actions: {
    setSelectedAll(selectAll: boolean) {
      if (selectAll != null) this.selectAll = selectAll;
    },

    setEditor(editor: monaco.editor.IStandaloneCodeEditor) {
      this.editor = markRaw(editor);
    },

    toggleCopyAction() {
      this.copyAction = true;

      setTimeout(() => {
        this.copyAction = false;
      }, 500);
    },

    updatePosition(position: monaco.Position | null) {
      this.position = position;
    },

    inputChanged(isChanged: boolean = true) {
      this.fresh = isChanged === false;
    },

    disposeEditor() {
      this.editor = null;
      this.loading = false;
    },
  },
});
